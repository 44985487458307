var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "board-container"
  }, [_c('div', {
    staticClass: "board-table board-table--view",
    attrs: {
      "role": "table"
    }
  }, [_c('div', {
    attrs: {
      "role": "rowgroup"
    }
  }, [_c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    attrs: {
      "role": "columnheader"
    }
  }, [_vm._v(" " + _vm._s(_vm.board.subject) + " ")])]), _c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    attrs: {
      "role": "cell"
    }
  }, [_c('ul', {
    staticClass: "board-view-data"
  }, [_c('li', [_vm._v("작성일 : " + _vm._s(_vm.board.createdAt.toDate()))]), _c('li', [_vm._v("조회수 : " + _vm._s(_vm.board.viewCount))])])])]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    attrs: {
      "role": "cell"
    }
  }, [_c('div', {
    staticClass: "board-view-file"
  }, [_c('span', {
    staticClass: "head"
  }, [_c('svg', {
    staticStyle: {
      "enable-background": "new 0 0 409 513.6"
    },
    attrs: {
      "version": "1.1",
      "id": "레이어_1",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "x": "0px",
      "y": "0px",
      "viewBox": "0 0 409 513.6",
      "xml:space": "preserve"
    }
  }, [_c('path', {
    attrs: {
      "d": "M41.5,452.8v-353c0-11,9-20,20-20h60v-19c0-11,9-20,20-20c11,0,20,9,20,20v141c0,11-9,20-20,20c-11,0-20-9-20-20v-82h-40v82\n                                c0,33.1,26.9,60,60,60c33.1,0,60-26.9,60-60v-122h145.9c11,0,20,9,20,20v353c0,11-9,20-20,20H61.5C50.5,472.8,41.5,463.8,41.5,452.8\n                                M85.3,39.8H61.5c-33.1,0-60,26.9-60,60v353c0,33.1,26.9,60,60,60h285.9c33.1,0,60-26.9,60-60v-353c0-33.1-26.9-60-60-60H197.7\n                                c-8.5-22.8-30.5-39-56.2-39S93.9,17,85.3,39.8"
    }
  })]), _vm._v(" 첨부파일 : ")]), _c('ul', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    staticClass: "lists"
  }, [_vm._m(0), _vm._m(1), _vm._m(2), _vm._m(3), _vm._m(4)])])])]), _c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    attrs: {
      "role": "cell"
    }
  }, [_c('div', {
    staticClass: "board-content"
  }, [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.board.content)
    }
  })])])])])]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    staticClass: "board-table board-table--preview",
    attrs: {
      "role": "table"
    }
  }, [_vm._m(5)]), _c('div', {
    staticClass: "board-buttons"
  }, [_c('div', {
    staticClass: "left"
  }, [_c('a', {
    staticClass: "button button--border",
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_c('span', [_vm._v("리스트")])])])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', [_c('a', {
    attrs: {
      "href": ""
    }
  }, [_vm._v("파일제목_01.jpg,")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', [_c('a', {
    attrs: {
      "href": ""
    }
  }, [_vm._v("파일제목_02.jpg,")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', [_c('a', {
    attrs: {
      "href": ""
    }
  }, [_vm._v("파일제목_03.jpg,")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', [_c('a', {
    attrs: {
      "href": ""
    }
  }, [_vm._v("파일제목_04.jpg,")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', [_c('a', {
    attrs: {
      "href": ""
    }
  }, [_vm._v("파일제목_05.jpg")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "role": "rowgroup"
    }
  }, [_c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    attrs: {
      "role": "columnheader"
    }
  }, [_vm._v(" 이전글 ")]), _c('div', {
    attrs: {
      "role": "cell"
    }
  }, [_c('div', {
    staticClass: "title"
  }, [_c('a', {
    attrs: {
      "href": ""
    }
  }, [_vm._v("이전 게시글 제목입니다.")])])])]), _c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    attrs: {
      "role": "columnheader"
    }
  }, [_vm._v(" 이전글 ")]), _c('div', {
    attrs: {
      "role": "cell"
    }
  }, [_c('div', {
    staticClass: "title"
  }, [_c('a', {
    attrs: {
      "href": ""
    }
  }, [_vm._v("다음 게시글 제목입니다.")])])])])]);

}]

export { render, staticRenderFns }